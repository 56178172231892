<template>
    <b-modal title="Edit base item" v-model="editModalActive" no-close-on-backdrop size="lg">
        <template #default>
            <b-form-group>
                <label>Name:</label>
                <b-form-input
                    type="text"
                    placeholder="Name"
                    v-model="editObject.name"
                />
            </b-form-group>
            <b-form-group>
                <label>Selected design:</label>
                <v-select
                    v-model="editObject.selected_design"
                    :reduce="designConfig => designConfig.id"
                    label="name"
                    :options="designConfigs"
                />

            </b-form-group>
            <b-form-group>
                <label>Icon pack:</label>
                <v-select
                    v-model="editObject.icon_pack"
                    :reduce="iconPack => iconPack.id"
                    label="name"
                    :options="iconPacks"
                />
            </b-form-group>

            <b-row>
                <b-col>
                    <b-form-group>
                        <label>Icon:</label>
                        <b-form-input
                            type="text"
                            placeholder="Icon"
                            v-model="editObject.icon"
                        />
                    </b-form-group>
                </b-col>
                <b-col class="d-flex justify-content-center">
                    <feather-icon class="mt-2" v-if="editObject.icon_pack === 0" :icon="editObject.icon" size="40"/>
                    <fa
                        class="mt-2"
                        v-else
                        size="3x"
                        :icon="editObject.icon"
                    />
                </b-col>
            </b-row>

            <hr/>

            <Translations class="mt-3" :base-url="`/api/management/v1/base_item/${  editObject.id}`" :fields="[{name: 'Name', field: 'name', type: 0}]" v-on:translationsChanged="$emit('itemModified')"/>
        </template>
        <template #modal-footer>

            <b-button variant="danger" @click="editModalActive = false">
                <feather-icon
                    icon="XIcon"
                    class="mr-50"
                />
                <span class="align-middle">Close</span>
            </b-button>

            <b-button variant="warning" @click="editBaseItem">
                <feather-icon
                    icon="SaveIcon"
                    class="mr-50"
                />
                <span class="align-middle">Save</span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>

    import {BButton, BFormGroup, BFormInput, BModal, BRow, BCol} from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import Translations from '@/views/Translation/Translations'

    export default {
        components: {
            BButton,
            BModal,
            BFormInput,
            BFormGroup,
            BRow,
            BCol,
            Translations,
            vSelect
        },
        props:{
            designConfigs: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                editObject: {},
                editModalActive: false,

                iconPacks: [
                    {
                        id: 0,
                        name: 'Feather icon'
                    },
                    {
                        id: 1,
                        name: 'Font Awesome'
                    }
                ]
            }
        },
        methods: {
            open(item) {
                this.editObject = JSON.parse(JSON.stringify(item))
                this.editModalActive = true
            },
            editBaseItem() {
                const thisIns = this
                const loadPromise = this.$http.put(`/api/management/v1/base_item/${  this.editObject.id}`, this.editObject)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Base item saved')
                    thisIns.editModalActive = false
                    thisIns.$emit('itemModified')
                    thisIns.$store.dispatch('verticalMenu/loadBaseItems')
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })
            }
        }
    }

</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>