<template>
    <b-modal title="Add new" v-model="addModalActive" no-close-on-backdrop>
        <template #default>
            <b-form-group>
                <label>Name:</label>
                <b-form-input
                    type="text"
                    placeholder="Name"
                    v-model="addObject.name"
                />
            </b-form-group>
            <b-form-group>
                <label>Selected design:</label>
                <v-select
                    v-model="addObject.selected_design"
                    :reduce="designConfig => designConfig.id"
                    label="name"
                    :options="designConfigs"
                />

            </b-form-group>
            <b-form-group>
                <label>Icon pack:</label>
                <v-select
                    v-model="addObject.icon_pack"
                    :reduce="iconPack => iconPack.id"
                    label="name"
                    :options="iconPacks"
                />
            </b-form-group>

            <b-row>
                <b-col>
                    <b-form-group>
                        <label>Icon:</label>
                        <b-form-input
                            type="text"
                            placeholder="Icon"
                            v-model="addObject.icon"
                        />
                    </b-form-group>
                </b-col>
                <b-col class="d-flex justify-content-center">
                    <feather-icon class="mt-2" v-if="addObject.icon_pack === 0" :icon="addObject.icon" size="40"/>
                    <fa
                        class="mt-2"
                        v-else
                        size="3x"
                        :icon="addObject.icon"
                    />
                </b-col>
            </b-row>
        </template>
        <template #modal-footer>

            <b-button variant="danger" @click="addModalActive = false">
                <feather-icon
                    icon="XIcon"
                    class="mr-50"
                />
                <span class="align-middle">Close</span>
            </b-button>

            <b-button variant="primary" @click="addBaseItem">
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">Add</span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>

    import {BButton, BFormGroup, BFormInput, BModal, BRow, BCol} from 'bootstrap-vue'
    import vSelect from 'vue-select'

    export default {
        components: {
            BButton,
            BModal,
            BFormInput,
            BFormGroup,
            BRow,
            BCol,
            vSelect
        },
        props:{
            designConfigs: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                addObject: {
                    icon_pack: 0,
                    icon: ''
                },
                addModalActive: false,

                iconPacks: [
                    {
                        id: 0,
                        name: 'Feather icon'
                    },
                    {
                        id: 1,
                        name: 'Font Awesome'
                    }
                ]
            }
        },
        methods: {
            open() {
                this.addModalActive = true
            },
            addBaseItem() {
                const thisIns = this
                const loadPromise = this.$http.post('/api/management/v1/base_item', this.addObject)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Base item added')
                    thisIns.addModalActive = false
                    thisIns.addObject = {
                        icon_pack: 0,
                        icon: ''
                    }
                    thisIns.$store.dispatch('verticalMenu/loadBaseItems')
                    thisIns.$emit('itemAdded')
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })
            }
        }
    }

</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>