<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card>

                <b-button variant="primary" @click="$refs.addBaseItemModal.open()">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>

                <basic-table :columns="columns" :data="items" v-slot="props">
                    <span v-if="props.column.displayType === 1" class="d-flex justify-content-center">
                        <feather-icon v-if="props.row.icon_pack === 0" :icon="props.row.icon" size="40"/>
                        <fa
                            v-else
                            size="2x"
                            :icon="props.row.icon"
                        />
                    </span>
                    <span v-else-if="props.column.displayType === 2" class="d-flex justify-content-center">
                        <b-badge v-if="props.formattedRow[props.column.field] === 'Yes'" variant="light-success">
                            Yes
                        </b-badge>
                        <b-badge v-else variant="light-danger">
                            No
                        </b-badge>
                    </span>
                    <div v-else-if="props.column.displayType === 3" class="d-flex justify-content-center">

                        <b-button variant="warning" @click="$refs.editBaseItemModal.open(props.row)" class="mr-1">
                            <feather-icon
                                icon="EditIcon"
                            />
                        </b-button>

                        <b-button variant="danger" @click="removeItem(props.row.id)">
                            <feather-icon
                                icon="Trash2Icon"
                            />
                        </b-button>
                    </div>
                    <span v-else-if="props.column.displayType === 4" class="d-flex justify-content-center">
                        {{ getDesignConfig(props.row.selected_design).name }}
                    </span>
                    <span v-else class="d-flex justify-content-center">
                        {{ props.formattedRow[props.column.field] }}
                    </span>

                </basic-table>
            </b-card>
        </b-overlay>

        <AddModal ref="addBaseItemModal" :design-configs="designConfigs" v-on:itemAdded="loadData"/>

        <EditModal ref="editBaseItemModal" :design-configs="designConfigs" v-on:itemModified="loadData"/>

    </div>
</template>
<script>

    import {BBadge, BButton, BCard, BOverlay} from 'bootstrap-vue'
    import BasicTable from '@/views/components/BasicTable'
    import AddModal from '@/views/BaseItem/AddModal'
    import EditModal from '@/views/BaseItem/EditModal'

    export default {
        components: {
            AddModal,
            EditModal,
            BOverlay,
            BCard,
            BButton,
            BasicTable,
            BBadge
        },
        data() {
            return {

                dataLoaded: false,
                items: [],

                designConfigs: [],

                columns: [
                    {
                        label: 'Icon',
                        displayType: 1,
                        field: 'icon',
                        filterOptions: {
                            enabled: false
                        }
                    },
                    {
                        label: 'Name',
                        displayType: 0,
                        field: 'name',
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: 'Selected design',
                        displayType: 4,
                        field: 'selected_design',
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: 'Fully translated',
                        displayType: 2,
                        field(rowObj) {
                            return (rowObj.fully_translated) ? 'Yes' : 'No'
                        },
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Yes / No'
                        }
                    },
                    {
                        label: 'Actions',
                        displayType: 3,
                        field: 'actions',
                        filterOptions: {
                            enabled: false
                        }
                    }
                ]

            }
        },
        methods: {
            loadData() {
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get('/api/management/v1/base_item/')
                loadPromise.then(function(response) {
                    thisIns.items = response.data
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })

                const designConfigsPromise = this.$http.get('/api/management/v1/config/design/base_item')
                designConfigsPromise.then(function(response) {
                    thisIns.designConfigs = response.data
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })

                Promise.all([loadPromise, designConfigsPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })

            },
            removeItem(id) {
                const thisIns = this
                const loadPromise = this.$http.delete(`/api/management/v1/base_item/${  id}`)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Base item removed')
                    thisIns.$store.dispatch('verticalMenu/removeBaseItem', id)
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            getDesignConfig(designConfigId) {
                return this.designConfigs.find(designConfig => designConfig.id === designConfigId) || {name: 'Unknown'}
            }
        },
        mounted() {
            this.loadData()
        }
    }

</script>